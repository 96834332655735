.container1{
    /* height:190vh; */

    /* background: url("student.jpg"); */
    padding:10px;
    overflow: auto;
    /* background: chocolate; */
    /* display: grid; */
    /* place-content: center; */
    /* place-items: center; */
    /* background-position: center; /* Center the image */
    /* background-repeat: no-repeat; /* Do not repeat the image */
    /* background-size: cover; */ 
    /* padding-bottom: 30px; */
}
.outer-layout{
    /* max-height:650px; */
    max-width:80vw;
    background: rgb(7, 91, 170);
    margin: auto;
    padding:10px;
    /* border: 3px solid red;
    margin-bottom: 100px !important; */
    border-radius: 7px;
  
}
.outer-layout h1{
    text-align: center;
    color:white;
    /* margin-top: 20px; */
}


.outer-layout .form-inner{
    padding:20px 10px 10px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap:5px;
    /* margin-bottom: 10px; */
    /* border:2px solid red; */


}
form{
padding:3px;
/* border: 3px solid red;
margin-bottom: 100px; */
}
.form-control{
    /* border:2px solid red; */
padding:10px;

    /* paddingpx; */
}

.form-control label{
    display: block;
    margin-bottom: 10px;
    color:white;
}
.form-control input{
    padding :8px 8px  8px 5px;
    width:100%;
    outline:none;
    font-size: 15px;
    border-radius: 7px;
    border:none;
    /* padding-left: 2px; */

}
.form-control select{
    padding :8px 8px  8px 5px;
    width:100%;
    outline:none;
    font-size: 15px;
    border-radius: 7px;
    border:none;
    
}
/* the following is for styling the file input  */
.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .custom-file-input::before {
    content: 'Select a photo';
    display: inline-block;
    background: rgb(15, 5, 5);
    border: 1px solid rgb(2, 1, 1);
    border-radius: 5px;
    padding: 10px 10px;
    outline: none;
    color:white;
    white-space: nowrap;
    cursor: pointer;
    /* text-shadow: 1px 1px #fff; */
    font-weight: 700;
    font-size: 10pt;
  }
  .custom-file-input:hover::before {
    border-color: black;
  }
  .custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }
.btn-register{
    padding:10px;
    width:50%;
    margin:auto;
    display: block;
    /* align-items: center;
    align-content: center; */
    text-transform: uppercase;
    outline:none;
    border:none;
    /* margin-left: 100px; */

    /* border:3px solid red; */
    border-radius: 5px;
    color:white;
    background: rgb(3, 5, 102);
    cursor:pointer;
    font-size: 15px;

}
.btn-register:hover{
    opacity: 0.7;
}
@media only screen and (max-width: 700px) {
    .outer-layout .form-inner{
        grid-template-columns: 1fr;
    }
    .search-form form {
        /* display:grid; */
        flex-direction: column;
        
         /* /* grid-template-columns: 1fr 1fr ;  */
        /* grid-template-rows: 1fr  1fr 1fr ;
        /* display: block !important; */
        /* background: red; */ 
    }
    .btn-search{
        /* display: block !important; */
    }
    .first-input{
        /* display:grid; */
        /* display: block; */
    }
}

@media only screen and (min-width: 700px) {
    /* .outer-layout form{
        grid-template-columns: 1fr;
    } */
    /* .container1{
        height:100vh;
    } */
}


/* the below is for the student Details file */
.container2{
    height:100vh;
    padding:10px;
    background: rgb(175, 63, 63);
}

.outer {
    /* height:80vh; */
    margin:auto;
    max-width: 800px;
    background: rgb(99, 154, 206);
    padding:10px;
    /* display: grid; */

}
.outer input{
    /* border:2px solid red; */
    /* margin:auto; */
    width:70%;
    font-size: 15px;
    border-radius: 5px 0 0px 5px;
    
    border:none;
    outline:none;
    padding:10px;
    margin-bottom: 20px;
}
.search-icon{
    /* border:1px solid red; */
    padding:10px;
    background: white;
    border-top-right-radius: 5px;
    border-bottom-right-radius:5px ;
    cursor:pointer;
    /* border-radius:0 px 5px 5px 0px ; */

}
.classes{

    display: grid;
    grid-template-columns: 1fr 1fr;
    place-content: center;
    gap:15px;
    margin-top: 30px;

   /* text-align: center; */
    /* place-items: center; */
    /* place-content: center; */
}
.class-list{
    background: rgb(61, 10, 10);
    border-radius: 5px;
    cursor: pointer;

    /* color:white; */
    /* max-width: 300px; */
 

}
.class-list:hover{
    opacity: 0.5;
}
.class-list p{
    color:white;
        padding:20px;

    
    /* text-underline-offset: none; */

}
 .linker{
    text-decoration: none  ;

 }
/* link{
    color:white;
} */
.outer h3{
    color:rgb(0, 18, 182);
    border:2px solid rgb(224, 197, 197);
    padding:5px;
    max-width: 30%;
    background: wheat;
    /* background: yellow; */
}
.table-layout{
    /* border:4px solid red; */
    overflow-x: auto;

}

table{
    border:1px solid rgb(127, 92, 209);
    border-collapse: collapse;
}
/* tr{
    border:2px solid black;
} */
th, td {
    border:1px solid rgb(192, 140, 140);

    padding: 5px;
    color:white;
    /* text-align: center; */

  }
  th {
    text-align: left;
  }
  .container3
  {
    height:100vh;
    padding:10px;
    overflow-y:auto;
    background: rgb(175, 63, 63);
  }
  .container3 .outer{
      max-width: 90%;
      background: rgb(26, 54, 5);
  }

  td:nth-child(12)  {
   text-align: center;
  }
  td:nth-child(11)  {
    text-align: center;
   }

   /* student fees section
    */
    .search-form{
        max-width: 1000px;
        margin: auto;
        margin-top: 15px;
        /* margin:10px; */
        margin-bottom: 15px;
        padding:15px;


    }
    .search-form h4 {
        text-align: center;
        padding:5px;
        color:white;
        margin-bottom: 10px;
        font-size: 20px;
        background: rgb(136, 84, 84);

    }
    .search-form form{
        /* border:3px solid red; */
        display:flex;
        justify-content: center;
        /* flex-direction: row; */
        /* max-width: 500px; */
        /* grid-template-columns: 1fr 0.5fr 0.3fr 0.4fr; */ 
    }
    .search-form form input{
        /* border:2px solid red; */
        padding:10px;
        font-size: 15px;
        /* max-width: 400px; */
        /* border:none; */
        outline:none;


    }
     /* .search-form form select{

     } */
    .search-form form select{
        /* border:3px solid red; */
        outline:none;
        padding:10px;
        font-size: 15px;
        /* padding:40px; */
    }

    .btn-search{
        background: rgb(83, 158, 139);
        color:white;
        padding: 10px;
        border:none;
        text-transform: uppercase;
        outline: none;
        cursor: pointer;
        
    }
    .btn-search:hover{
        opacity: 0.8;
    }
.attendance-outer{
    padding:10px;
    background: rgb(196, 203, 211);
    padding-bottom: 20px;
    overflow: auto;
}

    /* FOR STUDENT ATTENDANCE */
    .attendance-outer h1{
        background: rgb(58, 47, 47);
        color:white;
        max-width: 800px;
        margin: auto;
    }


.attendance-outer table {
    border:none;
    background: red;
    
    /* padding:100px; */
    /* max-width: 800px; */
}
.attendance-outer table thead{
    border:none;
    background: rgb(92, 85, 180);
   
} 
.attendance{
        padding:20px;
        margin: 20px;
        /* border:2px solid red; */
    }
.attendance td{
        background: rgb(235, 227, 227);
        /* border:none; */
        padding:30px;
        color:black;
        margin:30px;
    }
 .present{
        background: rgb(52, 184, 0) !important ;
        /* color:red; */
        text-transform: uppercase;
    }
 .absent{
        background: red !important;
        text-transform: uppercase;

    }



    .admitCard-outer{
        /* border:2px solid red; */
        /* padding:100px; */
        /* height:100vh; */
        margin:auto;
        margin: 100px 100px 0 100px;




    }

    .admitCard-outer .link{
        display: block;
        text-align: left;
        padding:20px;
        background: rgb(0, 11, 109);
        margin:auto;
        color:white;
        text-decoration: none;
        margin-top: 20px;
        border-radius: 10px;

        /* border:2px solid red; */
    }
    .admitCard-outer .link:hover{
        /* opacity: 0.8; */
        background: darkorange;
        color:black;
    }
    .admitCardouter{
        overflow:auto;


        
        /* display: block; */
        
        /* border:2px  solid red; */





    }

    /* for admit card */
    /* .container5{
        display: grid;
        grid-template-areas: Top Bottom;
    } */
    
.admitCard
{
    display: grid;
    grid-template-areas: 'top top top'
    'middle middle middle'
    'bottom bottom bottom';
    grid-gap: 10px;
    overflow:auto;
    background-color: #d8e1e9;
    padding: 10px;
    width:600px;
    padding-bottom: 30px;
    /* height:300px; */
}
.admitCardTop{
    grid-area: top;
    /* border: 2px solid red; */
    display:grid;
    background:rgb(90, 33, 33);
    grid-template-columns: 0.1fr 1fr;
}
.admitCardTop img{
   width:90px;
   height: 80px;
}
.admitCardMiddle{
    grid-area:middle;
    /* border: 2px solid red; */
    background:rgb(196, 165, 111);
    padding:0 20px 20px 20px;

}
.admitCardBottom{
    grid-area: bottom;
    /* border: 2px solid red; */
    background:rgb(127, 92, 209);
    padding:3px;


}

.schooldetails{
    text-align: center;
    color:white;
    margin: auto;
}


.details{
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
}

.details p{
    /* border:2px solid red; */
    padding:5px;
}
.details img{
    width:80px ;
    
}

.arrange{
    margin-bottom:50px;
    /* border: 2px solid red; */
    display: grid;
    overflow:auto;
    place-content: center;
    place-items: center;
    /* text-align: center; */
    /* margin: auto; */
    /* display: grid;
    grid-template-columns: 1fr 1fr; */
}


.exam form{
        /* border: 2px solid red; */
        display: grid;
        width: 50%;
        place-content: center;
        grid-template-columns: 1fr 0.1fr;
        margin: auto;
        margin-bottom: 40px;

        /* place-items: center; */
    } 
        
.printcmd{
    padding:10px;
    background:red;
    outline:none;
    border:none;
    color:white;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
    margin:auto;
    /* margin: auto; */

}
.printCMD{
    text-align: center;
    display: flex;
    align-items: center;
    align-content: center;

}
.examNumber{
    padding:10px;
    /* margin-bottom: 40px; */
    /* width: 300px; */
    outline: none;
    /* margin: auto; */
    /* text-align: center; */
    /* display: grid;
    place-content: center;
    place-items: center; */
    /* margin-left: 200px; */
    /* margin:auto; */
    /* display: flex;
    justify-content: center;
    justify-items: center; */

}

@media print {
    @page { size: 110mm 54.5mm; }
    .printcmd{
        display:none;
    }
  }


  .prints{
      display: grid;
      place-content: center;
      place-items: center;
      gap:10px

  }

  .la{
      display:grid;
      place-items: center;
      place-content: center;
  }

  .income-button {
      display:grid;
      margin:200px;
      margin-bottom: 10px;
      margin-top: 50px;
      gap:10px;
place-content: center;      
/* place-items: center; */


  }


  .income-button .link{
      /* text-align: left; */
      padding:10px;
      background: cyan;
      border:none;
      cursor:pointer;
      font-size: 15px;
      /* color:white; */


  }

  .income-button .link:hover{
      background: darkcyan;
      color:white;
      
  }
  .income-h1{
   
        /* text-align: center,
        background: 'red',
        margin: '0 100px 0 100px', */
        margin:auto;
        margin:0 100px 0 100px;
        text-align: center;
        /* text-decoration: indianred; */
        background: rgb(102, 32, 7);
        color:white;
        text-transform: capitalize;
        /* border: 2px solid rgb(21, 0, 78); */
        /* overflow: auto; */
        
     
  }


.incomeform{
    border:2px solid red;
    margin: auto;
}

/* select option:hover{
    cursor: pointer;
} */

.allincomedetails{
    /* display: inline; */
    /* border:2px solid  */
    margin:100px;
    background: rgb(245, 241, 238);
    display: grid;
    margin-top: 0;
    overflow: auto;
        /* border:2px solid black; */

    margin-bottom: 5px;
}
.outerIncome{
    /* border:2px solid black; */
    padding:10px;

}
.innerIncome{
    display:grid;
    grid-template-columns: 0.1fr 0.5fr 1fr;
    grid-template-rows:  1fr;
    padding:10px;
    border-bottom: 2px solid black;
   

}
.allIncome p{
    /* padding:40px; */
}


.disable{
    opacity: 0.5;

  }
 .disable:hover{
    opacity: 0.5;

  }

#footer{
    grid-area:footer;
    display:grid;
    place-items: center;
    place-content: center;
    padding:4px;
    padding-bottom: 0px;
    bottom:0px;
    text-align: center;
    background: rgb(0, 110, 255);
    color:white;
    /* position: fixed; */
}
.emoji{
    /* display: inline-block; */
    color:red;
    font-size: 15px;
}


@media only screen and (min-width: 800px) {
  
  #footer{
margin-top: 115px;
/* bottom:0px; */
position: relative;
  }
  }
  
#sidebar {
    background: #56a50c;
    grid-area: sidebar;
    /* overflow-y: scroll; */
    overflow-y: auto;
    padding: 20px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    height:100vh;
  }
  #sidebar{
  }
  .sidebar__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #f3f4f6;
    margin-bottom: 30px;
    /* color: #E85B6B; */
  }
  
  .sidebar__img {
    display: flex;
    align-items: center;
  }
  
  .sidebar__title > div > img {
    width: 75px;
    object-fit: contain;
  }
  
  .sidebar__title > div > h1 {
    font-size: 18px;
    display: inline;
  }
  
  .sidebar__title > i {
    font-size: 18px;
    display: none;
  }
  
  .sidebar__menu > h2 {
    color: white;
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 5px;
    padding: 0 10px;
    font-weight: 700;
    background: rgb(1, 119, 50);
    padding:10px;
    /* border:20px solid red; */
    border-radius: 10px;
  }
  
  .sidebar__link {
    color: #350bf0;
   
    cursor: pointer;
    border-radius: 3px;
    display: grid;
    grid-template-columns: 0.2fr 1fr;
    margin-bottom: 5px;
    /* border: 2px solid red; */

    /* text-decoration: none; */
  }
  
  .active_menu_link {
    background: #d61663;
    color: #350bf0;
  }
  
  .active_menu_link .linked {
    color: white;
  }
  
  .sidebar__link  .linked{
    text-decoration: none;
    color: white;
    padding: 10px;
    font-weight: 700;
    /* margin-right: 2px; */
    /* border: 2px solid red; */

    
  }
  .sidebar__link:hover{
    background:#d61663;
    /* color:white !important; */
  }
  /* .sidebar__link .linked:hover{
    color:white;
  } */
  
  .sidebar__link > i {
    margin-right: 10px;
    font-size: 18px;
    padding: 10px;
    width: 18px;
    /* border: 2px solid red; */
  }
  
  .sidebar__logout {
    margin-top: 20px;
    padding: 10px;
    color: #350bf0;
  }
  
  .sidebar__logout  .linked {
    text-decoration: none;
    color: white;
    font-weight: 700;
    text-transform: uppercase;
  }
  
  .sidebar__logout > i {
    margin-right: 10px;
    font-size: 18px;
  }
  
  .sidebar_responsive {
    display: inline !important;
    z-index: 9999 !important;
    left: 0 !important;
    position: absolute;
    /* overflow: auto; */
    overflow-y: scroll;
    /* overflow-y: scroll; */
  }
  
  @media only screen and (max-width: 978px) {
    #sidebar {
      display: none;
    }
  /* the below is for the child element i of the sidebar_title */
    .sidebar__title > i {
      display: inline;
    }
  }
  
*{
  margin:0;
  padding:0px;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  font-weight: 1000;
}
/* .disable{
  margin-top:10px ;
  max-width: 30%;
  display: block;

}
.enable{
  margin-top:10px ;
  max-width: 30%;
  display: block;
} */
          /* // style={{ marginTop: '10px', maxWidth: '30%', display: 'block' }} */

          .notFound{
          
            height: 100vh;
            /* border: 1px solid red; */
            overflow: hidden;
            width: 100%;
         
           
          }
          .notFound img{
            /* border: 1px solid red; */
          height: 100vh;
         margin: auto;
         display: block;

          }
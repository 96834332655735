main {
    background: #f3f4f6;
    grid-area: main;
    overflow-y: auto;
  }
  .card-handler{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    
    /* grid-template-rows: 1fr  2fr; */
 }
  .main__container {
    padding: 20px 35px;
  }
  
  .main__title {
    display: flex;
    align-items: center;
  }
  
  .main__title > img {
    max-height: 100px;
    object-fit: contain;
    margin-right: 20px;
  }
  
  .main__greeting > h1 {
    font-size: 24px;
    color: #2e4a66;
    margin-bottom: 5px;
  }
  
  .main__greeting > p {
    font-size: 14px;
    font-weight: 700;
    color: #a5aaad;
  }
  
  .main__cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 30px;
    margin: 20px 0;
  }
  
  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 70px;
    padding: 25px;
    border-radius: 5px;
    background: #ffffff;
    box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #ffffff;
  }
  
  .card_inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .card_inner > span {
    font-size: 25px;
  }
  
  .charts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin-top: 50px;
  }
  
  .charts__left {
    padding: 25px;
    border-radius: 5px;
    background: #ffffff;
    box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #ffffff;
  }
  
  .charts__left__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .charts__left__title > div > h1 {
    font-size: 24px;
    color: #2e4a66;
    margin-bottom: 5px;
  }
  
  .charts__left__title > div > p {
    font-size: 14px;
    font-weight: 700;
    color: #a5aaad;
  }
  
  .charts__left__title > i {
    color: #ffffff;
    font-size: 20px;
    background: #ffc100;
    border-radius: 200px 0px 200px 200px;
    -moz-border-radius: 200px 0px 200px 200px;
    -webkit-border-radius: 200px 0px 200px 200px;
    border: 0px solid #000000;
    padding: 15px;
  }
  
  .charts__right {
    padding: 25px;
    border-radius: 5px;
    background: #ffffff;
    box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #ffffff;
  }
  
  .charts__right__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .charts__right__title > div > h1 {
    font-size: 24px;
    color: #2e4a66;
    margin-bottom: 5px;
  }
  
  .charts__right__title > div > p {
    font-size: 14px;
    font-weight: 700;
    color: #a5aaad;
  }
  
  .charts__right__title > i {
    color: #ffffff;
    font-size: 20px;
    background: #39447a;
    border-radius: 200px 0px 200px 200px;
    -moz-border-radius: 200px 0px 200px 200px;
    -webkit-border-radius: 200px 0px 200px 200px;
    border: 0px solid #000000;
    padding: 15px;
  }
  
  .charts__right__cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 30px;
  }
  
  /* .card1 {
    background: #d1ecf1;
    color: #35a4ba;
    text-align: center;
    padding: 25px;
    border-radius: 5px;
    font-size: 14px;
  } */
  
  .card2 {
    background: #d2f9ee;
    color: #38e1b0;
    text-align: center;
    padding: 25px;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .card3 {
    background: #d6d8d9;
    color: #3a3e41;
    text-align: center;
    padding: 25px;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .card4 {
    background: #fddcdf;
    color: #f65a6f;
    text-align: center;
    padding: 25px;
    border-radius: 5px;
    font-size: 14px;
  }
  

  @media only screen and (max-width:800px){
    .card-handler{
      grid-template-columns: 1fr 1fr;
    }
  }
  @media only screen and (max-width: 600px) {
    /* .main__cards {
      grid-template-columns: 1fr;
      gap: 10px;
      margin-bottom: 0;
    }
  
    .charts {
      grid-template-columns: 1fr;
      margin-top: 30px;
    } */
    .card-handler{
      grid-template-columns: 1fr ;
    }
  }

  /* css for loading component */
  .loader{
    width:300px;
    height:300px;
    margin:auto;
    /* background: red; */
    /* display:block; */
  }
  /* css for message components */

  .danger{
    background: red;
    color:white;
    margin:auto;
    max-width: 80%;
    padding:10px;
    margin-top: 10px;

  }
  .success{
    background: green;
    color:white;
    padding:10px;
    margin:auto;
    max-width: 80%;
    margin-top: 10px;

  }
  .variant-p{
    margin:auto;
    text-align: center;
    font-size: 20px;
    /* border:2px solid rgb(1, 0, 88); */
  }
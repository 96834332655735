.navbar {
    background: #ffffff;
    grid-area: nav;
    background:steelblue;

    height: 70px;
    display: flex;
    align-items: center;
    /* align-content: center; */
    justify-content: space-between;
    padding: 0 30px 0 30px;
    /* overflow:auto; */
    /* border-bottom: 1px solid #fc1100; */
  }
  .navbar p{
    padding:2px;
    /* overflow:hidden; */
    /* display:grid; */
    /* place-content: center;
    place-items: center; */
    /* border */
    /* overflow:hidden; */
    margin:auto;
    font-size: 30px;
    text-transform: uppercase;
    /* display: inline; */
    color:white;
        /* font: red !important; */

  }
  .nav_icon {
    display: none;
  }
  /* .loggedinas{
    font-size: 15px;
    color:white;
    background: rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
  } */
  
  .nav_icon > i {
    font-size: 26px;
    color: #a5aaad;
  }
  
  .navbar__left > a {
    margin-right: 30px;
    text-decoration: none;
    color: #a5aaad;
    font-size: 15px;
    font-weight: 700;
  }
  
  .navbar__left .active_link {
    color: #265acc;
    border-bottom: 3px solid #265acc;
    padding-bottom: 12px;
  }
  
  .navbar__right {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .navbar__right > a {
    margin-left: 20px;
    text-decoration: none;
  }
  
  .navbar__right > a > i {
    color: #a5aaad;
    font-size: 16px;
    border-radius: 50px;
    background: #ffffff;
    box-shadow: 2px 2px 5px #d9d9d9, -2px -2px 5px #ffffff;
    padding: 7px;
  }
  
  @media only screen and (max-width: 978px) {
    .nav_icon {
      display: inline;
    }
    .navbar p{
      font-size: 22px;
    }
  }
  
  @media only screen and (max-width: 500px) {
    .navbar__left {
      display: none;
    }
    .navbar p{
      display: none;
    }
  }

  /* @media only screen and (max-width: 580px) {
  
    .navbar p{
      display: none;
    }
  } */
  .loggedinas p{
    font-size: 15px;
    /* border:3px solid black; */
    /* border-radius: 50px; */

  }

  .loggedinas img {
    border-radius: 50%;
    border:none;
    width:40px;

  }

 